<template>
  <div class="page">
    <div class="title">{{ info.title }}</div>
    <div class="desc">
      {{ info.description }}
    </div>
    <div class="logo">
      <img src="../../../static/doctor/hospital.jpg" />
    </div>
    <div class="bg">
      <strong style="margin-right: 2.5px">·</strong>联系方式：{{ info.tel }}
    </div>
    <div class="bg">
      <strong style="margin-right: 2.5px">·</strong>地理位置：{{ info.address }}
    </div>
    <div class="list">
      <div class="list-title" style="height: 32px; line-height: 32px; font-weight: bold">
        医院相册
      </div>
      <div class="tab-area">
        <div
          class="tab"
          v-for="(item, index) in tabs"
          :key="index"
          :class="tab == index ? 'tab-active' : ''"
          @click="changeTab(index)"
        >
          {{ item }}
        </div>
      </div>
      <div class="voices" v-show="tab == 0">
        <img
          v-for="(item, index) in environment"
          :key="index"
          class="voice"
          :src="$host + item"
        />
      </div>
      <div class="voices" v-show="tab == 1">
        <video
          v-for="(item, index) in video"
          :key="index"
          class="voice"
          :src="$host + item"
          style="width: 218px; height: 218px"
          poster="../../../static/community/fm2.png"
          controls
        ></video>
      </div>
      <div class="voices" v-show="tab == 2">
        <img
          v-for="(item, index) in honor"
          :key="index"
          class="voice"
          :src="$host + item"
        />
      </div>
      <div class="voices" v-show="tab == 3">
        <img
          v-for="(item, index) in qualification"
          :key="index"
          class="voice" 
          :src="$host + item"
        />
      </div>
    </div>
    <div style="display: flex; justify-content: center">
      <div v-show="info.apply_status == 0" class="submit-btn">审核中</div>
      <div v-show="info.apply_status == 2" class="submit-btn" @click="exit">取消入驻</div>
      <div v-show="info.apply_status == 3" class="submit-btn" @click="join">申请入驻</div>
      <div v-show="info.apply_status == 1" class="submit-btn" @click="join">重新申请</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      info: {},
      environment: [],
      video: [],
      honor: [],
      qualification: [],
      tabs: ["医院环境", "医院视频", "荣誉展示", "营业资格"],
      tab: 0,
      is_apply: "",
    };
  },
  mounted() {
    this.is_apply = this.$route.query.is_apply;
    console.log(this.is_apply);
    this.getInfo();
  },
  computed: {
    doctor() {
      return JSON.parse(this.$cache.getCache("doctorInfo"));
    },
  },
  methods: {
    getInfo() {
      let data = { id: this.$route.query.id };
      this.$request.hospitalinfo(data).then((res) => {
        if (res.code == 1) {
          this.info = res.data;
          this.environment = res.data.environment.split(",");
          // 医院视频
          this.video = res.data.video.split(",");
          // 荣誉展示
          this.honor = res.data.honor.split(",");
          // 营业资格
          this.qualification = res.data.qualification.split(",");
        } else {
          this.$store.commit("showToast", res.msg);
        }
      });
    },

    changeTab(i) {
      this.tab = i;
    },
    join() {
      this.$request.joinhospital({ hospital_id: this.info.id }).then((res) => {
        if (res.code == 1) {
          this.$store.commit("showToast", res.msg);
          setTimeout(() => {
            this.$router.go(-1);
          }, 1500);
        } else {
          this.$store.commit("showToast", res.msg);
        }
      });
    },
    exit() {
      let data = {
        doctors_id: this.doctor.id,
        hospital_id: this.info.id,
      };
      this.$request.cancelApply(data).then((res) => {
        if (res.code == 1) {
          this.$store.commit("showToast", res.msg);
          setTimeout(() => {
            this.$router.go(-1);
          }, 1500);
        } else {
          this.$store.commit("showToast", res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.page {
  width: 910px;
  padding: 10px 0;
  min-height: 620px;
  background: #f9fbfb;
}
.title {
  font-size: 18px;
  color: #333;
  font-weight: bold;
  text-align: center;
  height: 36px;
  line-height: 36px;
}
.desc {
  color: #999;
  font-size: 14px;
  line-height: 28px;
  padding: 5px;
}
.logo {
  margin: 20px 0;
  text-align: center;
  height: 500px;
  img {
    width: 830px;
    height: 500px;
  }
}
.bg {
  height: 40px;
  background: #f6f4f4;
  padding-left: 20px;
  margin: 10px 0;
  line-height: 40px;
}
.tab-area {
  width: 512px;
  height: 40px;
  background: #f6f4f4;
  display: flex;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  color: #666;
  margin: 5px 0;
}
.tab {
  width: 128px;
}
.tab:hover {
  cursor: pointer;
}
.tab-active {
  background: #fd9852;
  color: #fff;
}
.voices {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  .voice {
    width: 218px;
    height: 218px;
    margin-right: 9.5px;
    margin-bottom: 10px;
  }
}
.submit-btn {
  width: 130px;
  height: 42px;
  background: linear-gradient(129deg, #fbc2ae 0%, #fe6039 100%);
  opacity: 1;
  border-radius: 21px;
  line-height: 42px;
  text-align: center;
  color: #fff;
}
.submit-btn:hover {
  cursor: pointer;
}
</style>
